<template>
  <div class="social-links">
    <SocialMediaLink v-for="(link,index) in this.data" :link="link" :key="index" />
  </div>
</template>

<script>
import SocialMediaLink from "@/Components/SocialMediaLink";
export default {
  name: "SocialMedia",
  components: {SocialMediaLink},
  props: ['data']
}
</script>

<style scoped>
.social-links {
  @apply mx-auto max-w-2xl justify-center mt-4 block;
  display: flex;
  gap: 1rem;
}
</style>