<template>
    <a class="linktree-link" :href="this.linkData.link"
       :target="this.linkData.target ? this.linkData.target : '_blank'" v-on:click="clickAction($event, linkData)">
      <div class="linktree-link__icon">
        <i :class="[' '] + (this.linkData.icon ? this.linkData.icon : ' fa fa-link')" />
      </div>
      <div class="linktree-link_text">
        <div class="linktree-link__title">{{ this.linkData.title }}</div>
        <div class="linktree-link__description">{{ this.linkData.description }}</div>
      </div>
    </a>
</template>

<script>
export default {
  name: "LinkTreeLink",
  props: ['linkData', 'clickAction']
}
</script>

<style lang="scss" scoped>
.linktree-link {
  @apply grid grid-cols-5 flex-row shadow rounded-xl overflow-hidden bg-linkCard p-1 pr-3 w-full gap-2 items-center;

  &:hover {
    @apply bg-gray-400 text-white;
  }


  &_text {
    @apply col-span-4;
  }
  &__title {
    @apply text-[1rem];
    font-weight: 600;
  }

  &__description {
    @apply line-clamp-2 md:line-clamp-1;
    font-size: .9rem;
    font-weight: 300;
  }

  &__icon {
    // width: 9rem;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 5rem;

    i {
      @apply text-[2.4rem] md:text-[2.8rem]
    }

    i {
      --fa-beat-fade-opacity: 1;
      --fa-beat-fade-scale: 1.05;
      --fa-animation-duration: 2s;
    }
  }
}

</style>