<template>
  <div class="linktree-container">
    <div class="ka-logo">
      <img draggable="false" ondragstart="return false;" :src="this.logo.src" :alt="this.logo.alt">
    </div>
    <LinkTree :clickAction="clickAction" :links="this.links" :error="errorMessage" :loaderState="loaderState"/>
    <SocialMedia :data="this.socialMedia"/>
  </div>
  <button v-if="this.mode === 'development'" class="reload-button" onclick="window.location = window.location">
    <i class="fa-light fa-arrow-rotate-right"></i> Reload
  </button>
</template>

<script>
import Axios from "axios"
import LinkTree from "@/Components/LinkTree";
import SocialMedia from "@/Components/SocialMedia";
import {isEqual} from "lodash"

export default {
  name: 'App',
  components: {SocialMedia, LinkTree},
  data() {
    return {
      mode: 'production',
      reload: {current: 0, max: 30, loadTime: 120000},
      loaderState: 'active',
      errorMessage: '',
      links: [],
      socialMedia: [],
      background: '/fallback_background.jpg',
      logo: {
        src: '/ka-logo.png',
        alt: 'Küchen Aktuell GmbH - SocialMedia Links'
      }
    }
  },
  methods: {
    initBackground() {
      document.querySelector('body').style.setProperty('--background', 'url(' + this.background + ')');
    },
    async loadData() {
      try {
        const response = await Axios({
          method: "POST",
          url: process.env.NODE_ENV === 'production' ? 'https://linktree.kuechen-aktuell.de/api/' :
              ('http://' + process.env.VUE_APP_BACKEND_SERVICE_HOST  + ':' + process.env.VUE_APP_BACKEND_SERVICE_PORT) + '/api',
        });

        // Set/update the Links
        // let links = response.data.data.attributes.Links.filter(item => (item.state === true && item.link !== ''));
        let linkTreeData = response.data.linkTree;

        let links = linkTreeData.Links;
        if (!isEqual(links, this.links)) {
          this.links = links;
        }

        // Set/update socialmedia links
        let socialMedia = linkTreeData.Socialmedia;
        if (!isEqual(socialMedia, this.socialMedia)) {
          this.socialMedia = socialMedia;
        }

        // Set/update the background
        if (linkTreeData.background.data.length > 0) {
          let background = process.env.VUE_APP_BACKEND_CMSURL + linkTreeData.background.data[0].attributes.formats.thumbnail.url;
          if (background !== null && !isEqual(background, this.background)) {
            this.background = background;
            document.querySelector('body').style.setProperty('--background', 'url(' + this.background + ')');
          }
        }

        // Hide the Loader
        this.loaderState = 'inactive';

      } catch (error) {
        let that = this;
        setTimeout(function(){
          console.log(error);
          that.errorMessage = 'Einige Inhalte konnten nicht geladen werden. Laden Sie die Seite bitte erneut ...'
          that.loaderState = 'broken';
          that.$forceUpdate();
        },5000);
      }
    },

    // Submit ClickEvent to Matomo
    clickAction($event, linkData) {
      console.log($event, linkData);
      // window.location = linkData.link; // Workaround for signage
      // _paq.push(['trackEvent', 'LinkTree', 'Link Click', linkData.title]);
    }
  },
  mounted() {
    // Load the Content
    this.initBackground();
    this.loadData();

    // Pull new Data from CMS all 120s
    let updateData = window.setInterval(() => {
      this.loadData();
      this.$forceUpdate();
      this.reload.current = this.reload.current + 1;
      if (this.reload.current >= this.reload.max) {
        window.clearInterval(updateData);
      }
    }, this.reload.loadTime)
  }
}
</script>