<template>
  <div class="linktree-loader" v-if="loaderState !== 'inactive'">
    <i :class="['fa-light fa-3x' + (loaderState !== 'broken' ? ' fa-spinner fa-spin' : ' fa-triangle-exclamation')]" ref="loader"></i>
    <div class="error pt-4" :v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkTreeLoader",
  props: {
    loaderState: {
      type: String
    },
    error: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.linktree-loader {
  @apply bg-linkCard rounded-xl p-4 text-center;
}
</style>