<template>
  <div :v-if="link.state === true" class="social-links__link">
    <a :href="link.link" :target="link.target" :title="link.label">
      <i :class="'social-links__link-icon fa-2x ' + link.icon" :style="'color:' + this.convertToRGBA(link.hoverColor, .6) + ';--hover-color:#' + link.hoverColor" />
    </a>
  </div>
</template>
<script>
export default {
  name: "SocialMediaLink",
  props: ['link'],
  methods: {
    convertToRGBA(color, alpha) {
      const [r, g, b] = color.match(/\w\w/g).map(x => parseInt(x, 16));
      return 'rgba(' + r + ',' + g + ',' + b  + ',' + alpha + ')';
    }
  }
}
</script>
<style lang="scss">
.social-links__link {
  &-icon {
    @apply text-dark;
    &:hover {
      color: var(--hover-color) !important;
    }
  }
}
</style>