<template>
  <div class="linktree-links max-h-[620px] pb-0.5" ref="linkTreeContainer">
  <LinkTreeLoader :error="error" :loaderState="loaderState"/>
    <div class="linktree-links__linkcontainer" ref="linkTreeLinksContainer">
      <div class="linktree-links__link" v-for="(linkData, index) in this.links" :key="index">
        <LinkTreeLink :linkData="linkData"  :click-action="clickAction" />
      </div>
    </div>
  </div>
</template>
<script>
import LinkTreeLink from "@/Components/LinkTreeLink";
import LinkTreeLoader from "@/Components/LinkTreeLoader";
export default {
  name: "LinkTree",
  components: {LinkTreeLoader, LinkTreeLink},
  props: ['links', 'clickAction', 'error','loaderState'],
  methods: {
    hasScrollbar () {
      if (this.$refs !== undefined) {
        let linkTreeContainer = this.$refs.linkTreeContainer;
        let linksContainer = this.$refs.linkTreeLinksContainer;
        if (linkTreeContainer.clientHeight < linksContainer.scrollHeight) {
          linkTreeContainer.classList.add('has-scrollbar');
        }
        let childNodes = Array.from(this.$refs.linkTreeLinksContainer.childNodes);
        let visibleNodes = childNodes.filter(childNode => childNode.offsetTop < linkTreeContainer.clientHeight - '');
        let lastVisible = visibleNodes[visibleNodes.length -1];
        return lastVisible;
      }
    }
  },
  updated() {
  this.hasScrollbar()
  },
  watch: {
    loaderState: {
      handler(){
        this.$forceUpdate();
        // this.hasScrollbar();
      },
    },
    links: {
      handler(){},
      deep: true
    }
  }
}
</script>
<style lang="scss">
.linktree-links {
  @apply relative overflow-auto w-full gap-4 grid grid-cols-1 relative;

  // Show Scrollbar Info
  &.has-scrollbar {
    .linktree-links__scrollinfo {
      display: flex !important;
    }
  }

  &__linkcontainer {
    @apply grid grid-cols-1 gap-3;
  }

}
</style>